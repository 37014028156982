.sidebar {
	background-color: #2B4C7E;
}
.this-week-in-civic-tech {
  margin: 0;
  padding: 0;
  li {
    list-style: none;
    padding-bottom: 1rem;
    border-bottom: 1px solid #ccc;
    margin-bottom: 1rem;
  }

}
.content-upgrade {
	display: none;
	padding: 1rem;
	border: 1px solid #2B4C7E;
	border-radius: 0.2rem;
}
.content-upgrade p {
	margin-top: 0;
	font-size: 0.75em;
}
.content-upgrade a {
	display: table;
	margin: 0 auto;
	border-radius: 0.2rem;
	text-align: center;
	padding: 0 14px;
	background: #2B4C7E;
	color: #fff;
	box-shadow: 0 4px 6px rgba(50,50,93,.11), 0 1px 3px rgba(0,0,0,.08);
	font-weight: 600;
	letter-spacing: .025em;
	text-decoration: none;
	-webkit-transition: all .15s ease;
	transition: all .15s ease;
}
.content-upgrade a:hover {
	border-bottom: none;
	transform: translateY(-1px);
	box-shadow: 0 7px 14px rgba(50,50,93,.1), 0 3px 6px rgba(0,0,0,.08);
}
form#survey {
	fieldset {
		border: none;
		margin: 0;
		padding: 0;
		margin-bottom: 2rem;
		display: flex;
		flex-direction: column;
		legend {
			font-weight: 500;
			font-size: 1.4rem;
		}
		.row {
			display: flex;
			flex-direction: row;
			align-items: baseline;
			margin-left: 0.5rem;
		}
		input {
			margin-right: 0.4rem;
		}
		label.legend {
			font-weight: 500;
			font-size: 1.4rem;
		}
		textarea {
			margin-top: 0.5rem;
			font-size: 1.2rem;
			width: 80%;
		}
	}
	button {
		border-radius: 0.3rem;
		font-size: 1.1rem;
		color: #fff;
		padding: 0.5rem 1rem ;
		background: #2B4C7E;
		cursor: pointer;
	}
}
#survey-response {
	visibility: hidden;
}
form#ck-simple {
	font-size: 16px;
	font-weight: 400;
	margin: 2rem 0;
	padding: 0 0 2rem 0;
	input {
		font-size: 16px;
		border-radius: .25rem;
		line-height: 1.25;
		padding: .5rem 0.75rem;
		box-shadow: 0 2px 4px 0 rgba(0,0,0,.1);
		color: #606f7b;
		margin-bottom: 1rem;
		max-width: 20rem;
		width: 100%;
		border: 1px solid #dae1e7;
	}
	.btn {
		border-radius: .25rem;
		background-color: #3490dc;
		padding: .5rem 1rem;
		color: #fff;
		font-size: 16px;
		cursor: pointer;
	}
	.btn:hover {
		background-color: #2779bd;
	}
	p {
		margin-top: 0;
	}
	.small {
		font-size: 0.75rem;
	}
}
